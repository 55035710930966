export default function preloader() {
    if (!window.preloader) {
        return;
    }
    const loader = document.querySelector('#preloader');
    const loader_line = document.querySelector('#preloader .line');
    const loader_percent = document.querySelector('#preloader .percent');
    const move = {
        width: -100,
        width_finish: 0,
        step: 1,
        percent: 0,
        percent_finish: 100
    }

    function move_line_width() {
        delay = 45;
        if (move.width < move.width_finish) {
            move.width += move.step;
            move.percent = move.width + 100;
            loader_line.style.right = move.width + '%';
            if (Math.abs(move.width) < 50) {
                delay = Math.round(delay - ((100 - move.width) / 6));
            }
            return true;
        }

        return false;
    }

    function print_percent() {
        move.percent = move.width + 100;
        loader_percent.innerHTML = move.percent + '%';
    }

    function clear_loader() {
        loader.classList.remove('visible');
        setTimeout(() => {
            loader.remove();
        }, 1000);
    }
    let delay = 10;
    
    function go() {
        setTimeout(() => {
            print_percent();
            if (move_line_width()) {
                go();
            } else {
                window.preloader = false;
                setTimeout(clear_loader, 100);  
            }
        }, delay);
    }

    go();
}