import { start } from "@popperjs/core";

export default function NotFound() {
   if (window.innerWidth < 768) {
        document.querySelector('.game-start').classList.add('mobile');
    } else {
        document.querySelector('.game-start').classList.add('desktop');
   }
    
document.querySelector('.game-start').classList.add('show');
    
    const canvas = document.getElementById('gameCanvas');
    const ctx = canvas.getContext('2d');

    const basketWidth = 100;
    const basketHeight = 48;
    const basketImg = new Image();
    basketImg.src = '/assets/images/404/basket.webp';  
    
    let basket_dx = 10;

    let basket_y_padding = window.innerWidth > 768 ? 160 : 60;

    let basket = {
        x: canvas.width / 2 - basketWidth / 2,
        y: canvas.height - basketHeight - basket_y_padding,
        width: basketWidth,
        height: basketHeight,
        dx: basket_dx,
        image: basketImg
    };
    resizeCanvas();
    
    const element_404 = new Image();
    element_404.src = '/assets/images/404/404.webp';  
    const element_404_white = new Image();
    element_404_white.src = '/assets/images/404/404-white.webp'; 
    const element_logo = new Image();
    element_logo.src = '/assets/images/404/logo.webp';  
    const element_cube = new Image();
    element_cube.src = '/assets/images/404/kocka.webp'; 
    const element_405 = new Image();
    element_405.src = '/assets/images/404/405.webp'; 
    const element_choclet = new Image();
    element_choclet.src = '/assets/images/404/codeva-csoki.webp'; 
    const element_egg = new Image();
    element_egg.src = '/assets/images/404/egg.webp'; 
    const element_favicon = new Image();
    element_favicon.src = '/assets/images/404/favicon.webp'; 
    const element_fb = new Image();
    element_fb.src = '/assets/images/404/FB.webp'; 
    const element_sphere = new Image();
    element_sphere.src = '/assets/images/404/gomb.webp'; 
    const element_diamond = new Image();
    element_diamond.src = '/assets/images/404/gyemant.webp'; 
    const element_arrow_left = new Image();
    element_arrow_left.src = '/assets/images/404/nyil.webp'; 
    const element_arrow_right = new Image();
    element_arrow_right.src = '/assets/images/404/nyil2.webp'; 
    const element_octagon = new Image();
    element_octagon.src = '/assets/images/404/oktagon.webp'; 
    const element_download = new Image();
    element_download.src = '/assets/images/404/download.webp'; 
    
    const elements = [
        { name: 'download', number: 1, is_click: true, width: 200 * 0.7, height: 200 * 0.7, points: 60, speed: 1, image: element_download },
        { name: 'egg', number: 1, is_click: true, width: 200 * 0.7, height: 200 * 0.7, points: 100, speed:1, image: element_egg },
        { name: '404', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404 },
        { name: '404', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404 },
        { name: '404', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404 },
        { name: '404-white', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404_white },
        { name: '404-white', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404_white },
        { name: '404-white', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404_white },
        { name: '404-white', number: 100000, is_click: false, width: 173, height: 60, points: -1 * Math.round(20 + (Math.random() * 30)), image: element_404_white },
        { name: 'logo', number: 100000, is_click: false, width: 177, height: 20, points: Math.round(20 + (Math.random() * 10)), image: element_logo },
        { name: 'logo', number: 100000, is_click: false, width: 177, height: 20, points: Math.round(20 + (Math.random() * 10)), image: element_logo },
        { name: 'logo', number: 100000, is_click: false, width: 177, height: 20, points: Math.round(20 + (Math.random() * 10)), image: element_logo },
        { name: 'cube', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_cube },
        { name: '405', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_405 },
        { name: 'choclet', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_choclet },
        { name: 'favicon', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_favicon },
        { name: 'fb', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_fb },
        { name: 'sphere', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_sphere },
        { name: 'diamond', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_diamond },
        { name: 'arrow-left', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_arrow_left },
        { name: 'arrow-right', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_arrow_right },
        { name: 'octagon', number: 100000, is_click: false, width: 200 * 0.7, height: 200 * 0.7, points: Math.round(10 + (Math.random() * 10)), image: element_octagon },

    ];
    
    let elementInterval = 2000;
    let fallingElements = [];
    let score = 404;
    let time = 120;
    let timeTimer = false;
    let speed = 2;
    let speedMax = 10;
    let scoreAlpha = 0.3;
    let scoreHeight = 27;
    let scoreColor = '#8f00ff';
    let scoreColorPlus = '#8f00ff';
    let scoreColorMinus = '#ff0000';
    let gameStart = false;
    let gameOver = false;
    let gamePause = false;
    let gameStop = false;
    let into_position = false;

    // Billentyű leütések figyelése
    let rightPressed = false;
    let leftPressed = false;
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);
    document.querySelector('.start-game').addEventListener('click', startGameHandler);
    document.querySelectorAll('.restart-game').forEach((element) => {
        element.addEventListener('click', reStartGameHandler);
    });
    document.querySelector('.game-pause-button').addEventListener('click', pauseGameHandler);
    document.querySelector('.continues-game').addEventListener('click', continuesGameHandler);

    // Kattintás érzékelése
    canvas.addEventListener('click', (event) => {
        // Kattintás pozíciójának lekérése a canvas koordinátarendszerében
        const rect = canvas.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        const clickY = event.clientY - rect.top;

        fallingElements.forEach((element, index) => {
            if (clickX >= element.x && clickX <= element.x + element.width && clickY >= element.y && clickY <= element.y + element.height && element.is_click) {
                gameStop = true;
                if (element.name === 'egg') { 
                    document.querySelector('.easter-egg-text').classList.add('show');
                    setTimeout(() => {
                        document.querySelector('.easter-egg-text').classList.remove('show');
                    }, 4000);
                }

                if (element.name === 'download') {
                    window.estereggs.ghost();
                }
                setTimeout(function () {
                    gameStop = false;
                    gameLoop();
                }, 2000);
            }
        });
    });

    function keyDownHandler(e) {
        if (e.key === 'Right' || e.key === 'ArrowRight') {
            rightPressed = true;
        } else if (e.key === 'Left' || e.key === 'ArrowLeft') {
            leftPressed = true;
        }
    }

    function keyUpHandler(e) {
        if (e.key === 'Right' || e.key === 'ArrowRight') {
            rightPressed = false;
        } else if (e.key === 'Left' || e.key === 'ArrowLeft') {
            leftPressed = false;
        }
    }

    function startGameHandler() {
        score = 404;
        speed = 2;
        time = 120;
        gameStart = true;
        timer();
        document.querySelector('.game-start').classList.remove('show');
    }
    
    function pauseGameHandler() {
        gamePause = true;
        document.querySelector('.game-pause').classList.add('show');
    }
    
    function reStartGameHandler() {
        score = 404;
        gameOver = false;
        gamePause = false;
        speed = 2;
        time = time > 0 ? 120 : 150;
        fallingElements = [];
        scoreAlpha = 0.3;
        scoreHeight = 27;
        scoreColor = '#8f00ff';
        timer();
        document.querySelector('.game-end').classList.remove('show');
        document.querySelector('.game-pause').classList.remove('show');
        
        setTimeout(function () {
            generateFallingElementTimer();
        }, 2000);
        gameLoop();
    }
    
    function continuesGameHandler() {
        document.querySelector('.game-pause').classList.remove('show');
        setTimeout(() => {
            gamePause = false;
            timer();
            
            setTimeout(function () {
                generateFallingElementTimer();
            }, 2000);
            gameLoop();
        }, 1000);
    }

    function intro() { 
        if (gameStart) {
            return;
        }

        const element = fallingElements[0];

        if (element === undefined) {
            return;
        }

        if (element.name !== into_position) {
            into_position = element.name;
        } else {
            if (element.y < (window.innerHeight / 5) * 3) {
                return;
            }
            let x = basket.x;
            let different = (basket.x + basket.width / 2) - (element.x + element.width / 2);
            if (different > 50) {
                x -= basket.dx;
            } else if (different < -50) {
                x += basket.dx;
            } else {
                x = element.x + element.width / 2
            }
            basket.x = x; 
        }


    }

    function timer() {
        
        timeTimer = setInterval(() => {
            if (!gameStart || gamePause || gameStop) {
                clearInterval(timeTimer);
                return;
            }
            if (time <= 0) {
                clearInterval(timeTimer);
                return;
            }
            time--;
        
            let formatedTime = '';
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;
            if (minutes < 10) {
                formatedTime += '0';
            }
            formatedTime += minutes + ':';
            if (seconds < 10) {
                formatedTime += '0';
            }
            formatedTime += seconds;

            document.querySelector('#timer').innerText = formatedTime;
        }, 1000);
    }

    // Kosár mozgatása
    function moveBasket() {
        if (!gameStart) {
            return;
        }
        if (rightPressed && basket.x + basket.dx < canvas.width - basket.width) {
            basket.x += basket.dx;
        } else if (leftPressed && basket.x > basket.dx) {
            basket.x -= basket.dx;
        }
    }

    // Elemek véletlenszerű legenerálása
    function generateFallingElement() {
        if (gameOver || gameStop || gamePause) {
            return;
        }

        if (fallingElements.length >= 3) {
            return;
        }
        const index = Math.floor(Math.random() * elements.length);
        const element = elements[index];
        const xPosition = Math.floor(Math.random() * (canvas.width - element.width));
        
        if (element.name === 'egg' && elements[0].number > 0) {
            return;
        }

        if (!(gameOver || gameStop || gameStart) && element.number <= 100) {
            return;
        }

        if (element.number <= 0) {
            return;
        }
        elements[index].number--;
        fallingElements.push({
            ...element,
            x: xPosition,
            y: 0
        });
    }

    function generateFallingElementTimer() {
        generateFallingElement();
        setTimeout(function () {
            elementInterval = Math.floor(Math.random() * (5000 / speed));
            generateFallingElementTimer();
        }, elementInterval);
        
    }

    // Elemesés, sebesség növekedés
    function updateFallingElements() {
        if (time <= 0 && gameStart) {
            gameOver = true;
        }

        fallingElements.forEach((element, index) => {
            element.y += element.speed != undefined ? element.speed : speed;
            
            // Ellenőrizni, hogy elkapta-e a kosár
            if (element.y < basket.y && element.y > basket.y - basket.height && ((element.x <= basket.x && element.x + element.width >= basket.x) || (element.x <= basket.x + basket.width && element.x + element.width >= basket.x + basket.width))) {
                score -= element.points;
                scoreAlpha = 1;
                scoreHeight = 45;
                if (element.points > 0) {
                    scoreColor = scoreColorPlus;
                } else {
                    scoreColor = scoreColorMinus;
                }

                if (element.name === 'egg') {
                    document.querySelector('.easter-egg-text').classList.add('show');
                    setTimeout(() => {
                        document.querySelector('.easter-egg-text').classList.remove('show');
                    }, 4000);
                }

                if (element.name === 'download') {
                    window.estereggs.ghost();
                }

                fallingElements.splice(index, 1);

                if (score <= 0 && gameStart) {
                    score = 0;
                    gameOver = true;
                }
            }

            // Elemek eltávolítása, ha leértek
            if (element.y > canvas.height) {
                fallingElements.splice(index, 1);
            }
        });
    }

    // Rajzolás
    function drawBasket() {
        ctx.drawImage(basket.image, basket.x, basket.y, basket.width, basket.height);
    }

    function drawFallingElements() {
        fallingElements.forEach(element => {
            ctx.drawImage(element.image, element.x, element.y, element.width, element.height);
        });
    }

    function drawScore() {
        ctx.font = scoreHeight + 'px Rubik';
        ctx.fillStyle = scoreColor;
        ctx.globalAlpha = scoreAlpha;
        
        ctx.fillText(score, basket.x - (('' + score).length * scoreHeight / 3.3) + (basket.width / 2), basket.y + basket.height + 20 + (scoreHeight / 3));
        ctx.globalAlpha = 1;
        if (scoreAlpha > 0.2) {
            scoreAlpha -= 0.01;
        }
        if (scoreHeight > 27) {
            scoreHeight -= 0.5;
        }
    }

    function drawGameOver() {
        document.querySelector('.game-end').classList.remove('lose');
        document.querySelector('.game-end').classList.remove('win');
        document.querySelector('.game-end').classList.add('show');
        if (score <= 0 && time > 0) {
            document.querySelector('.game-end').classList.add('win');
        }else {
            document.querySelector('.game-end').classList.add('lose');
        }
    }

    function resizeCanvas() {
        if (window.innerWidth < 768) {
            document.querySelector('.game-start').classList.remove('desktop');
            document.querySelector('.game-start').classList.add('mobile');
        } else {
            document.querySelector('.game-start').classList.remove('mobile');
            document.querySelector('.game-start').classList.add('desktop');
        }

        if (window.innerWidth > 1200) {
            canvas.width = 1200;
        } else {
            canvas.width = window.innerWidth;
        }
        canvas.height = window.innerHeight;
        if (window.innerWidth / 100 / 1.5 < 10) {
            basket_dx = 10;
        }else if (window.innerWidth / 100 / 1.5 > 15) {
            basket_dx = 15;
        }else {
            basket_dx = window.innerWidth / 100 / 1.5;
        }

        basket_y_padding = window.innerWidth > 768 ? 160 : 60;
        basket.y = canvas.height - basketHeight - basket_y_padding;
        basket.dx = basket_dx;
    }

    // Fő játékkör
    function gameLoop() {
        resizeCanvas();
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawFallingElements();
        drawScore();
        drawBasket();
        intro();
        moveBasket();
        
        if (gameStop || gamePause) {
            return;
        }
        if (gameOver) {
            drawGameOver();
            return;
        }
        
        updateFallingElements();
        
        if (gameStart && speed < speedMax) {
            speed += 0.001;  // Sebesség növekedése
        }
        
        requestAnimationFrame(gameLoop);

    }
    
    generateFallingElementTimer();
    
    gameLoop();
}