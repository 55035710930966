import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function about() {
    let animation_tl;

    document.querySelectorAll(".animated-text").forEach((container) => {
        const text = container.textContent.trim();
        container.innerHTML = "";
        
        // Minden karakterhez létrehozunk egy <span> elemet
        text.split("").forEach(char => {
            const span = document.createElement("span");
            span.className = "letter";
            span.innerHTML = char === " " ? "&nbsp;" : char; // Használjunk &nbsp;-t szóközökhöz
            container.appendChild(span);
        });
    });

    function rearrangeArray(arr) {
        const result = [];
        const midIndex = Math.floor(arr.length / 2);

        result.push(arr[midIndex]); // Kezdjük a középső elemmel

        let left = midIndex - 1;
        let right = midIndex + 1;

        while (left >= 0 || right < arr.length) {
            if (left >= 0) {
                result.push(arr[left]);
                left--;
            }
            if (right < arr.length) {
                result.push(arr[right]);
                right++;
            }
        }

        return result;
    }

    function animation() {
        let step = 0;
        
        animation_tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".about",
                start: "top top",  // Amikor a szöveg a viewport 80%-ához ér
                end: "bottom top", // Amíg a szöveg el nem hagyja a viewport alsó 20%-át
                scrub: 2,
                pin: true,
                //markers: true
            }
        });
        
        document.querySelectorAll(".animated-text").forEach((container) => {
            const letters = container.querySelectorAll(".letter");
            
            // GSAP animációk beállítása
            animation_tl.set(letters, {
                opacity: 1,
                fontVariationSettings: '"wght" 100, "wdth" 150',
                letterSpacing: '0em',
                fontSize: '5vw'
            });

            // Idővonal a betűk animálásához

            const char_sort = rearrangeArray([...letters]);
            char_sort.forEach((letter, index) => {
                step += index;
                animation_tl.to(letter, {
                    duration: 0.1,          // Minden betű 0.3 másodpercig animálódik
                    fontVariationSettings: '"wght" 600, "wdth" 400',
                    letterSpacing: '0.05em',
                    fontSize: '7vw',
                    ease: "power2.out",     // Könnyű sima animáció
                    //stagger: 0.01           // Stagger az egyes betűk között
                }, step);  // Késleltetés index alapján
            });
        });
        
        animation_tl.to('.about .management .small', {
            width: (document.querySelectorAll('.about .management .small .image').length * 85),
            opacity: 1,
            marginTop: 15,
            marginBottom: 15,
            ease: "power1.inOut",
            duration: 100,
            onStart: () => {
                document.querySelector('.about .management .small').style.height = '110px';
            },
            onReverseComplete: () => {
                document.querySelector('.about .management .small').style.height = '0';
            },
        }, step);

        let card_min_top = 150
        document.querySelectorAll('.about .gallery .card').forEach((card, index) => {
            step += index;
            card.style.top = card_min_top + '%';
            card_min_top = gsap.utils.random(card_min_top + 300, card_min_top + 600);
            card.style.left = gsap.utils.random(50, window.innerWidth - card.offsetWidth) + 'px';
            card.style.transform = 'rotate(' + (Math.random() * 50) + 'deg) scale(' + gsap.utils.random(0.6, 3) + ')';
            animation_tl.to(card, {
                opacity: 1,
                top: '-50%',
                duration: 500,
            }, step);
        });

        ScrollTrigger.refresh();
    }

    animation();
    let resize_timer = false;
    window.addEventListener('resize', () => {
        if (window.currentWidth == window.innerWidth) {
            return;
        }
        if (animation_tl) {
            ScrollTrigger.killAll();
            animation_tl.kill();
        }
        clearTimeout(resize_timer);
        resize_timer = setTimeout(() => {
            animation();
        }, 500);
    });

    document.querySelectorAll('.about .management .small .image').forEach((image, index) => {
        image.addEventListener('click', () => {
            const id = image.getAttribute('data-id');
            document.querySelectorAll('.about .management .big .card').forEach((card) => {
                card.classList.remove('show');
            });
            document.querySelector('.about').classList.add('show');
            document.querySelector('.about .management .big .card[data-id=' + id + ']').classList.add('show');
        });
    });
    document.querySelectorAll('.about .management .big .card .close, .about .management .big .layer').forEach((close, index) => {
        close.addEventListener('click', () => {
            document.querySelector('.about').classList.remove('show');
            document.querySelectorAll('.about .management .big .card').forEach((card) => {
                card.classList.remove('show');
            });
        });
    });
}