import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function services() {
    let offsetX = - document.querySelector('.services .content > div').offsetWidth + window.innerWidth - (window.innerWidth / 4);
    let service_width = document.querySelector('.services .content .service').offsetWidth - 80;
    let animation_mm;
    function serviceImageSize() {
        const service_height = document.querySelector('.services .content .service').offsetHeight - 80;
        service_width = document.querySelector('.services .content .service').offsetWidth - 80;
        let service_image_height = 1000;
        document.querySelectorAll('.services .services-list .service').forEach((service, index) => {
            let height = (service_height - service.querySelector('.description').offsetHeight) < 600 ? (service_height - service.querySelector('.description').offsetHeight) : 600;
            
            if (height < 250) {
                0;
            }

            if (window.innerWidth <= 768) {
                height = 200;    
            }
            
            if (window.innerWidth <= 468) {
                height = 100;    
            }

            if(height < service_image_height) {
                service_image_height = height;
            }

            service.querySelector('.image').style.height = height + 'px';
            service.querySelectorAll('.ring').forEach((ring, index) => {
                ring.style.width = (service_height / 1.2) + 'px'; 
                ring.style.height = (service_height / 1.2) + 'px'; 
                
                ring.style.left = Math.floor(Math.random() * 100) + '%';
                if (ring.parentElement.classList.contains('top')) {
                    ring.style.top = Math.floor((Math.random() * 100) - 70) + '%';
                }else {
                    ring.style.top = Math.floor((Math.random() * 100) + 70) + '%';
                }
            });
        });
    };

    function animation() {
        animation_mm = gsap.matchMedia();

        // 768px felett (x irányú scroll)
        animation_mm.add("(min-width: 769px)", () => {
            gsap.to(document.querySelector(".services .content > div"), {
                x: () => offsetX + 'px',
                ease: "linear",
                scrollTrigger: {
                    trigger: document.querySelector(".services"),
                    pin: true,
                    scrub: true,
                    start: "top top",
                    end: "bottom+=100% top",
                    // markers: true,
                }
            });

            gsap.to(document.querySelector('.services .content > div .panel.title'), {
                transform: 'translate(0, 0) rotate(0)',
                opacity: 1,
                ease: "none",
                scrollTrigger: {
                    trigger: document.querySelector('.services'),
                    scrub: 1,
                    start: "top-=10px top",
                    end: "top top",
                }
            });
            gsap.to(document.querySelector('.services .content > div .panel.services-list'), {
                transform: 'translateX(0)',
                ease: "none",
                scrollTrigger: {
                    trigger: document.querySelector('.services'),
                    scrub: 1,
                    start: "-=10px top",
                    end: "top top",
                }
            });

            document.querySelectorAll('.services .services-list .service .mask').forEach((mask, index) => {
                if (mask.style.height) {
                    mask.remove();
                    return;
                }

                gsap.to(mask, {
                        left: service_width - mask.offsetWidth,
                        ease: "none",
                        scrollTrigger: {
                            trigger: document.querySelector('.services'),
                            scrub: 1,
                            start: "0% top",
                            end: "50% top",
                        }
                    });
            });

        });
        animation_mm.add("(max-width: 768px)", () => {
            gsap.set(document.querySelector('.services .content > div .panel.title'), {
                transform: 'translate(0, -100px) rotate(0)',
                opacity: 1,
            });
            
            gsap.set(document.querySelector('.services .content > div .panel.services-list'), {
                transform: 'translateX(0) translateY(-100px)',
                opacity: 1,
            });
            
            document.querySelectorAll('.services .services-list .service .mask').forEach((mask, index) => {
                if (mask.style.height) {
                    mask.remove();
                    return;
                }
                
                gsap.to(mask, {
                    left: service_width - mask.offsetWidth,
                    ease: "none",
                    scrollTrigger: {
                        trigger: document.querySelector('.services'),
                        scrub: 1,
                        start: "0% 20%",
                        end: "10% 20%"
                    }
                });
            });
        });

        ScrollTrigger.refresh();
    }

    serviceImageSize();
    animation();
    let resize_timer = false;
    window.addEventListener('resize', () => {
        if (window.currentWidth == window.innerWidth) {
            return;
        }
        if (animation_mm) {
            ScrollTrigger.killAll()
            animation_mm.kill();
        }
        clearTimeout(resize_timer);
        resize_timer = setTimeout(() => {
            serviceImageSize();
            offsetX = - document.querySelector('.services .content > div').offsetWidth + window.innerWidth - (window.innerWidth / 4);
            animation();
        }, 500);
    });

    setTimeout(() => {
        document.querySelector('#about-video').play();
    }, 2000);
};