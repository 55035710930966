export default {
    setCookie: function (name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    },
    getCookie: function (name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    scrollPercent: function () {
        const bodyST = document.body.scrollTop;
        const docST = document.documentElement.scrollTop;
        const docSH = document.documentElement.scrollHeight;
        const docCH = document.documentElement.clientHeight;
        
        
        return (docST + bodyST) / (docSH - docCH) * 100
    },
    isMobile: function () {
        return window.innerWidth < 768;
    },

    isTablet: function () {
        return window.innerWidth < 1024;
    },
    disableScroll: function() {
        window.addEventListener('scroll', this.preventScroll, { passive: false });
        window.addEventListener('wheel', this.preventScroll, { passive: false });
        window.addEventListener('touchmove', this.preventScroll, { passive: false });
    },
    enableScroll: function() {
        window.removeEventListener('scroll', this.preventScroll);
        window.removeEventListener('wheel', this.preventScroll);
        window.removeEventListener('touchmove', this.preventScroll);
    },
    preventScroll: function(event) {
        event.preventDefault();
        event.stopPropagation();
        return false;
    },
    copyClipboard: function (text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    },
}