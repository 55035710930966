import intro from './intro.js';
import hero from './hero.js';

import services from './services.js';
import about from './about.js';
import preloader from './preloader.js';

export default function animations(is_animation) {

    preloader();
    
    let heroAnimationDeley = 500;
    if (is_animation.intro) {
        window.scrollTo(0, 0);
        history.pushState(null, '', '/');
    }

    let preloader_timer = setInterval(() => {
        if (!window.preloader) {
            clearInterval(preloader_timer);
            if (is_animation.intro) {
                heroAnimationDeley = 8000;
            }
            
            hero(heroAnimationDeley);
            if (is_animation.intro) {
                intro();
            }
        }
    }, 10);
    
    services();
    about();
}