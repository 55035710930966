import { gsap } from 'gsap';

export default function hero(heroAnimationDeley) {
    const h1 = document.querySelector('.hero h1.step-1');
    const cursor = document.createElement('span');
    let animation_char;
    let animation_cursor;
    cursor.classList.add('cursor');
    cursor.innerText = '_';
    h1.append(cursor);
   function animation() {
        // Karakterek animálása GSAP-pal
        animation_char = gsap.fromTo(h1.querySelectorAll('.char'), 
            {
                // Kezdeti állapot
                filter: 'blur(10px)',
                scale: 0.1,
                opacity: 0
            }, 
            {
                // Animációs végállapot
                filter: 'blur(0px)',
                scale: 1,
                opacity: 1,
                duration: 0.2, // 200ms (GSAP másodpercben várja az értéket)
                ease: 'easeOutExpo',
                stagger: 0.1, // 100ms késleltetés az elemek között
                delay: 0.1, // Késleltetés az animáció indítása előtt
                onUpdate: function () {
                    const totalTargets = this.targets().length;
                    const currentProgress = this.progress(); // Progressz arány 0 és 1 között
                    const currentIndex = Math.floor(currentProgress * totalTargets);
                
                    // Az aktuálisan animált elem lekérése
                    if (this.targets()[currentIndex]) {
                        const char = this.targets()[currentIndex];
                        cursor.style.left = (char.offsetLeft + char.offsetWidth) + 'px';
                    }
                }
            }
        );

        // Cursor villogó animáció
        animation_cursor = gsap.fromTo(cursor, 
            {
                opacity: 0
            }, 
            {
                opacity: 1,
                duration: 0.4, // 400ms
                ease: 'linear',
                repeat: -1, // Végtelen ismétlődés (loop)
                yoyo: true // Felváltva megy vissza az eredeti állapotba
            }
        );
    }

    // Animáció indítása a megadott késleltetéssel
    setTimeout(() => {
        h1.classList.remove('d-none');
        animation();
    }, heroAnimationDeley);

    let resize_timer = false;
    window.addEventListener('resize', () => {
        if (window.currentWidth == window.innerWidth) {
            return;
        }
        if (animation_char) {
            animation_char.kill();
        }
        if (animation_cursor) {
            animation_cursor.kill();
        }
        clearTimeout(resize_timer);
        resize_timer = setTimeout(() => {
            animation();
        }, 500);
    });
}