import utils from "./utils";

export default function menu(is_animation) {
    let sections = new Map();
    let section_indexes = [];
    let section_active = false;
    let scrolling_top = 0;
    const menu = document.querySelectorAll('header nav ul li');
    const nav = document.querySelector('.nav-bar');

    const navigation = function (route = false, direction = 'down') {
        if (!route) {
            return;
        }

        scrolling = false;

        //utils.disableScroll();
        section_active = route;
        menu.forEach(li => {
            li.querySelector('a').classList.remove('active');
        });
        
        document.querySelector('title').innerHTML = document.querySelector('header nav ul li a[data-route="' + route + '"]').textContent + ' | ' + document.querySelector('title').getAttribute('data-title');
        
        if (document.querySelector('header nav ul li a[data-route="' + route + '"]')) {
            document.querySelector('header nav ul li a[data-route="' + route + '"]').classList.add('active');
            history.pushState(null, '', document.querySelector('header nav ul li a[data-route="' + route + '"]').getAttribute('href'));
        } else {
            history.pushState(null, '', '/');
        }

        let top = sections.get(route).top;

        if (direction === 'up') {
            top = sections.get(section_indexes[section_indexes.indexOf(route) + 1]).top - window.innerHeight;
        }
        
        temporarilyDisableScrolling(top, direction);

        navbarActive(nav.querySelector('ul li[data-route="' + route + '"]'));
    }

    const temporarilyDisableScrolling = function(top, direction) {
        setTimeout(() => {
            scrolling_top = top;
            document.documentElement.style.scrollBehavior = direction == 'direct'? 'auto' : '';
            window.scrollTo({
                top: top,
                behavior: direction == 'direct'? 'auto' : 'smooth'
            });
            document.documentElement.style.scrollBehavior = 'auto';
            setTimeout(() => {
                scrolling = true;
                //utils.enableScroll();
            }, 1000);
        }, 10); // Késleltetés
    }

    const navbarActive = function (current) {
        nav.querySelectorAll('ul li').forEach(li => {
            li.classList.remove('active');
            li.classList.add('inactive');
        });
        
        if (current) {
            current.classList.remove('inactive');
            current.classList.add('active');
        }
    };

    const sectionPositions = function () {
        sections = new Map();
        section_indexes = [];
        if (document.querySelector('.section-item') === null) { 
            return;
        }
        let last_route = 'home';
        sections.set('home', {
            top: 0,
            height: document.querySelector('.section-item[data-route="home"]').offsetHeight
        });
        
        document.querySelectorAll('.section-item:not([data-route="home"])').forEach((section, index) => {
            let height = section.offsetHeight;
            let top = sections.get(last_route).top + sections.get(last_route).height;
            if (section.offsetTop != 0) {
                top = section.offsetTop;    
            }else if (section.getAttribute('data-route') === 'services') {
                height = window.innerHeight * 3;
            }else if (section.getAttribute('data-route') === 'about') {
                height = window.innerHeight * 2;
            }
            
            sections.set(section.getAttribute('data-route'),{
                top: top,
                height: height
            });
            
            last_route = section.getAttribute('data-route');
        });

        section_indexes = Array.from(sections.keys());
        setBackground();
    };

    const setBackground = function () {
        sections.forEach((section, index) => {
            if (index == "services") {
                const spiral = document.querySelector('[data-position="services"]');
                spiral.style.top = (section.top - ((window.innerHeight / 100) * 15)) + 'px';
                spiral.style.opacity = 1;
            } else if (index == "about") {
                const abouts = document.querySelectorAll('[data-position="about"]');
                abouts.forEach(about => {
                    about.style.top = section.top + 'px';
                    about.style.opacity = 1;
                });
            } else if (index == "contact") {
                const diamond = document.querySelector('[data-position="contact"]');
                diamond.style.top = (section.top) + 'px';
                diamond.style.opacity = .6;
            }
        });
    }

    let scrollUpDownVisibleTimer = false;
    const scrollUpDownVisible = function () {
        if (scroll_down === null || scroll_up === null) {
            return;
        }

        scroll_down.classList.add('opacity-0');
        scroll_up.classList.add('opacity-0');
        scroll_down.classList.remove('opacity-1');
        scroll_up.classList.remove('opacity-1');
        clearTimeout(scrollUpDownVisibleTimer);
        scrollUpDownVisibleTimer = setTimeout(() => {
            scroll_down.classList.add('opacity-1');
            scroll_up.classList.add('opacity-1');
            scroll_down.classList.remove('opacity-0');
            scroll_up.classList.remove('opacity-0');
        }, 2000);
    };


    const navbar = function () {
        if (nav === null) {
            return;
        }
        nav.querySelectorAll('ul li').forEach(li => {
            li.addEventListener('click', function () {
                let route = this.getAttribute('data-route');
                navigation(route, 'direct');
                navbarActive(this);
                document.querySelector('body').classList.remove('nav-bar-active');
            });

            li.addEventListener('mouseover', function() {
                nav.querySelectorAll('ul li').forEach(li => {
                    li.classList.remove('active');
                });
              
                navbarActive(this);
            });
        });

        let set_section_active_timer = false;
        nav.querySelector('nav').addEventListener('mouseover', function () {
            if (!(utils.isMobile() || utils.isTablet())) {
                document.querySelector('body').classList.add('nav-bar-active');
                clearTimeout(set_section_active_timer);
            }
        });
        
        nav.querySelector('nav').addEventListener('mouseout', function () {
            if (!(utils.isMobile() || utils.isTablet())) {
                document.querySelector('body').classList.remove('nav-bar-active');
                set_section_active_timer = setTimeout(() => {
                    navbarActive(nav.querySelector('ul li[data-route="' + section_active + '"]'));
                }, 500);
            }    
        });
    }

    const scroll_down = document.querySelector('.scroll-down');
    const scroll_up = document.querySelector('.scroll-up');
    const scrollUpDown = function () {
        if (scroll_down === null || scroll_up === null) {
            return;
        }
        scroll_down.addEventListener('click', function () {
            if (section_indexes[section_indexes.indexOf(section_active) + 1] !== undefined) {
                navigation(section_indexes[section_indexes.indexOf(section_active) + 1]);
            }
        });
        
        scroll_up.addEventListener('click', function () {
            navigation(section_indexes[0], 'direct');
         });
    }

    const scroll = function () {
        if (window.scrollY > 10) {
            document.querySelector('header').classList.add('scrolling');
        } else {
            document.querySelector('header').classList.remove('scrolling');
        }
        
        const MoveHandler = function (y) {
            if (!scrolling) {
                return;
            }
            
            
            scrolling_top = scrolling_top + y;
            if (scrolling_top < 0) {
                scrolling_top = 0;
            }

            if (scrolling_top > document.querySelector('#scrolling').offsetHeight - window.innerHeight) {
                scrolling_top = document.querySelector('#scrolling').offsetHeight - window.innerHeight;
            }

            document.documentElement.style.scrollBehavior = 'auto';
            window.scrollTo(0, scrolling_top);
        };

        window.addEventListener('wheel', function (e) {
            if (document.querySelector('body').classList.contains('touching') || document.querySelector('body').classList.contains('nav-bar-active')) {
                return;
            }
            document.querySelector('body').classList.add('wheel');
            document.querySelector('body').classList.remove('touching');
            MoveHandler(e.deltaY);
        });
        window.addEventListener('touchstart', function (e) {
            document.querySelector('body').classList.remove('wheel');
            document.querySelector('body').classList.add('touching');
        });

        window.addEventListener('scroll', function (e) {
            e.preventDefault();
            scrollUpDownVisible();
            if (section_active == 'about') {
                const abouts = document.querySelectorAll('[data-position="about"]');
                let top = this.window.scrollY;
                if (top < sections.get('about').top) {
                    top = sections.get('about').top;
                } else if (top > sections.get('about').top + sections.get('about').height - window.innerHeight) {
                    top = sections.get('about').top + sections.get('about').height - window.innerHeight;
                }

                let scale = 0.6 + ((top - sections.get('about').top) / (sections.get('about').height - window.innerHeight) / 2);
                let opacity = 1.4 - (top - sections.get('about').top) / (sections.get('about').height - window.innerHeight);
                if (scale < 0.6) {
                    scale = 0.6;
                } else if (scale > 1.5) {
                    scale = 1.5;
                }

                abouts.forEach(about => {
                    about.style.top = top + 'px';
                    about.style.transform = 'scale(' + scale + ')';
                    about.style.opacity = opacity;
                });
            }
            
            if (window.scrollY > 10) {
                document.querySelector('header').classList.add('scrolling');
            } else {
                document.querySelector('header').classList.remove('scrolling');
            }

            if (sections.size > 0 && window.innerWidth > 768) {
                if (scrolling && sections.get(section_indexes[section_indexes.indexOf(section_active) + 1]) != undefined && window.scrollY > sections.get(section_indexes[section_indexes.indexOf(section_active) + 1]).top - window.innerHeight + ((window.innerHeight / 100) * 20)) {
                    navigation(section_indexes[section_indexes.indexOf(section_active) + 1]);
                } else if (scrolling && sections.get(section_active).top != undefined && window.scrollY < sections.get(section_active).top - ((window.innerHeight / 100) * 20)) {
                    navigation(section_indexes[section_indexes.indexOf(section_active) - 1], 'up');
                }
            }
        });
    };

    const menuClick = function () {
        menu.forEach(li => {
            li.querySelector('a').addEventListener('click', function (e) {
                if (sections.size > 0) {
                    e.preventDefault();
                    let route = this.getAttribute('data-route');
                    navigation(route, 'direct');
                    document.querySelector('header').classList.remove('mobil-menu-active');
                }
            });
        });
    }

    const mobileMenu = function () {
        document.querySelector('.hamburger-menu').addEventListener('click', function () {
            document.querySelector('header').classList.toggle('mobil-menu-active');
        });
    }
    const mobileMenuBtnChange = function () {
        if((utils.isMobile() || utils.isTablet()) && document.querySelector('header .btn-primary')){
            document.querySelector('header .btn-primary').classList.add('btn-secondary');
            document.querySelector('header .btn-primary').classList.remove('btn-primary');
        } else if(document.querySelector('header .btn-secondary')){
            document.querySelector('header .btn-secondary').classList.add('btn-primary');
            document.querySelector('header .btn-secondary').classList.remove('btn-secondary');
        }
    }

    let resize_timer = false;
    setTimeout(() => {
        sectionPositions();
        mobileMenuBtnChange();
        window.addEventListener('resize', function () {
            if (window.currentWidth == window.innerWidth) {
                return;
            }
            window.currentWidth = window.innerWidth;
            this.clearTimeout(resize_timer);

            resize_timer = setTimeout(() => {
                mobileMenuBtnChange();
                sectionPositions();
            }, 1000);
        });
        navbar();
        scrollUpDown();
        scroll();
        menuClick();
        mobileMenu();
    
        let route = false;
        menu.forEach(li => {
            if (li.querySelector('a').getAttribute('href') === window.location.pathname) {
                route = li.querySelector('a').getAttribute('data-route');
            }
        });

        if (route == 'home') {
            navbarActive(nav.querySelector('ul li[data-route="' + route + '"]'));
            history.pushState(null, '', '/');
            scrolling_top = 0;
            document.documentElement.style.scrollBehavior = 'auto';
            window.scrollTo({
                top: 0,
                behavior: 'auto'
            });
            section_active = route;
        } else if (route) {
            navigation(route, 'direct');
        }
    }, 100);
    
};