import codeva from './codeva.js';
import cool from './cool.js';
import ghost from './ghost.js';
import hiphop from './hiphop.js';
import hi from './hi.js';
import kumamon from './kumamon.js';
import robot from './robot.js';
import ufo from './ufo.js';
import unicorn from './unicorn.js';
import haha from './haha.js';
import bat from './bat.js';
import hippo from './hippo.js';
import balloon from './balloon.js';
import no from './no.js';
import haters from './haters.js';
import snorlax from './snorlax.js';
import fly from './fly.js';
import penguin from './penguin.js';
import winner from './winner.js';
import tank from './tank.js';
import mario from './mario.js';
import joker from './joker.js';
import rabbit from './rabbit.js';
import sensei from './sensei.js';
import bravo from './bravo.js';
import catrun from './catrun.js';
import doggy from './doggy.js';
import homer from './homer.js';
import lol from './lol.js';
import nyancat from './nyancat.js';
import sonic from './sonic.js';
import ohhh from './ohhh.js';
import britney from './britney.js';



window.estereggs = {};

export default function () {
    const eggs = [
        {function: codeva(), key: 'codeva', description: 'Cég neve, akinek az oldalát nézed'},
        {function: hi(), key: 'szia', description: 'köszönés' },
        {function: unicorn(), key: 'unikornis', description: 'mitikus egyszarvú állat' },
        {function: kumamon(), key: 'kumamon', description: 'japán kabalafigura, medve' },
        {function: haha(), key: 'haha', description: 'nevetés hangja' },
        {function: bat(), key: 'bat', description: 'éjszakai, repülő emlős angolul' },
        {function: robot(), key: 'robot', description: 'mesterséges intelligenciával rendelkező gép' },
        {function: hiphop(), key: 'hiphop', description: 'táncos műfaj' },
        {function: hippo(), key: 'hippo', description: 'vízben élő, nagytestű állat Afrikából' },
        {function: ufo(), key: 'ufo', description: 'ismeretlen repülő tárgy' },
        {function: cool(), key: 'cool', description: 'menő, divatos' },
        {function: balloon(), key: 'lufi', description: 'színes, levegővel teli dekoráció' },
        {function: no(), key: 'nem', description: 'tagadó válasz' },
        {function: haters(), key: 'haters', description: 'irigykedők, kritizálók angolul' },
        {function: snorlax(), key: 'snorlax', description: 'nagy, álmos Pokémon' },
        {function: fly(), key: 'fly', description: 'szárnyas rovar angolul' },
        {function: penguin(), key: 'pingvin', description: 'fekete-fehér, vízimadár' },
        {function: winner(), key: 'winner', description: 'győztes angolul' },
        {function: tank(), key: 'tank', description: 'lánctalpas katonai jármű' },
        {function: mario(), key: 'mario', description: 'híres videojáték karakter piros sapkában' },
        {function: joker(), key: 'joker', description: 'Batman gonosztevője' },
        {function: rabbit(), key: 'nyúl', description: 'ugráló, füles állat'},
        {function: sensei(), key: 'sensei', description: 'mester vagy tanár a harcművészetekben' },
        {function: bravo(), key: 'bravo', description: 'tapsolás, elismerés' },
        {function: ghost(), key: 'ghost', description: 'szellem angolul' },
        {function: britney(), key: 'britney', description: 'híres popénekesnő (Oops i did it again)' },
        {function: catrun(), key: 'catrun', description: 'nyávogó házi kedvenc' },
        {function: sonic(), key: 'sonic', description: 'gyors, kék videojáték karakter' },
        {function: doggy(), key: 'doggy', description: 'ugatós házi kedvenc' },
        {function: homer(), key: 'homer', description: 'egy vicces, sárga rajzfilmfigura' },
        {function: lol(), key: 'lol', description: 'nevetős rövidítés' },
        {function: ohhh(), key: 'ohhh', description: 'meglepett vagy csodálkozó hang' },
        {function: nyancat(), key: 'nyancat', description: 'internetes mém, szivárványos macska' },

    ];

    function generateEgg(element) {
        var random = Math.floor(Math.random() * eggs.length);
        element.parentNode.parentNode.querySelector('.easter-egg-detail .easter-egg').innerHTML = eggs[random].description;
        setTimeout(() => {
            element.parentNode.parentNode.querySelector('.easter-egg-detail').classList.add('show');
        }, 500);
    }

    if (document.querySelector('.easter-egg-random') && document.querySelector('.easter-egg-detail')) {
        document.querySelectorAll('.easter-egg-random').forEach(function (element) {
            element.addEventListener('click', function (e) {
                generateEgg(element);
            });
        });
        document.querySelectorAll('.easter-egg-detail .redo').forEach(function (element) {
            element.addEventListener('click', function (e) {
                generateEgg(element);
            });
        });
    }
};