import utils from './utils.js';
import menu from './menu.js';
import Swal from 'sweetalert2';
import cursor from './animations/cursor.js';

window.scrolling = true;
window.currentWidth = window.innerWidth;

const is_animation = {
        intro: true,
        intro_every_time: false,
        cursor: false,
        load3D: true,
};

menu(is_animation);

if (is_animation.cursor) {
    cursor();
}

 if (is_animation.intro && !is_animation.intro_every_time) {
    is_animation.intro = utils.getCookie('visited') === null
}

import animations from './animations/animations.js';
import contact from './contact.js';
if (document.querySelector('body.site-home')) {
    window.contact = contact;
    animations(is_animation);
}

if (document.querySelector('.copy-clipboard-email')) {
    document.querySelector('.copy-clipboard-email').addEventListener('click', function () {
        utils.copyClipboard('info@codeva.hu');
        const Toast = Swal.mixin({
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            }
        });
        Toast.fire({
            icon: "success",
            title: "Az e-mail cím a vágólapodra került."
        });
    });
}

if (document.querySelector('.copy-clipboard-phone')) {
    document.querySelector('.copy-clipboard-phone').addEventListener('click', function () {
        utils.copyClipboard('+36203298161');
        const Toast = Swal.mixin({
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
            }
        });
        Toast.fire({
            icon: "success",
            title: "Az telefonszám a vágólapodra került."
        });
    });
}

import NotFound from './404.js';
window.NotFound = NotFound;

let originalTitle = document.title;
document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
        originalTitle = document.title;
        document.title = 'Várunk vissza! | Codeva';  // Ha a tab inaktív, módosítjuk a címet
    } else {
        document.title = originalTitle;  // Ha a tab újra aktív, visszaállítjuk az eredeti címet
    }
});

import estereggs from './estereggs/init.js';
estereggs();