export default function () {
    let key = [67, 79, 68, 69, 86, 65]
    let ck = 0
    let max = key.length

    let codeva = function () {

        var shock = document.createElement('div')
        var img = new Image()
        img.src = data
        img.style.width = '350px'
        img.style.height = '300px'
        img.style.transition = '6s all linear'
        img.style.position = 'fixed'
        img.style.left = '-400px'
        img.style.bottom = '10%'
        img.style.zIndex = 999999

        document.body.appendChild(img)

        window.setTimeout(function () {
        img.style.left = 'calc(100% + 500px)'
        }, 50)

        window.setTimeout(function () {
        img.parentNode.removeChild(img)
        }, 6000)

    }

    let record = function (e) {

        if (e.which === key[ck]) {
        ck++
        } else {
        ck = 0
        }

        if (ck >= max) {
        codeva()
        ck = 0
        }

    }

    let init = function (data) {
        document.addEventListener('keyup', record)
    }

    let data = 'https://i.imgur.com/QbN03gd.gif'

    init(data)
    
     window.estereggs.codeva = codeva;
}