import { gsap } from 'gsap';
import utils from '../utils';

export default function intro() {

    document.querySelectorAll('section:not(.hero), footer, header nav li, header .hamburger-menu, .nav-bar, .nav-bar ul li, #hemisphere, .shape-ring, .hero .content h1, .hero .content .slider, .social a, .services-background-image, .scroll-down, .scroll-up').forEach((section) => {
        section.classList.add('d-none');
    });
        
    setTimeout(() => {
        document.querySelector('.intro-logo').classList.add('animations-start');
        
        const logo_params = {
            top: document.querySelector('header .logo').offsetTop,
            left: document.querySelector('header .logo').offsetLeft,
            width: document.querySelector('header .logo').offsetWidth,
            new_width: parseInt(window.innerWidth * 0.4),
            new_top: parseInt((window.innerHeight / 2) - ((window.innerWidth * 0.4 * 0.11) / 2)),
            new_left: parseInt(((window.innerWidth >= 1920 ? 1920 : window.innerWidth) / 2) - ((window.innerWidth * 0.4) / 2))
        };

        const introLogo = document.querySelector('.intro-logo.animations-start');
        gsap.set(introLogo, {
            width: logo_params.new_width + 'px',
            left: logo_params.new_left + 'px',
            top: logo_params.new_top + 'px'
        });
        gsap.timeline()
        .add(() => {
                document.querySelector('.btn-skip').classList.remove('d-none');
                gsap.timeline()
                    .fromTo('.btn-skip',
                        { opacity: 0 },
                        { opacity: 1, duration: 0.2, ease: 'back.out(1.7)' })
            }, .2)
            .add(() => {
                gsap.timeline()
                    .fromTo('.intro-logo .letter-c, .intro-logo .letter-o, .intro-logo .letter-d, .intro-logo .letter-e',
                        { y: 100 },
                        { y: 0, duration: 0.3, ease: 'power2.inOut' })
                    .to('.intro-logo .letter-c, .intro-logo .letter-o, .intro-logo .letter-d, .intro-logo .letter-e',
                        { y: -100, duration: 0.2, delay: 1, ease: 'power2.inOut' });
            }, .3)
            .add(() => {
                // Második rész
                gsap.timeline()
                    .fromTo('.intro-logo .letter-d, .intro-logo .letter-e, .intro-logo .letter-v',
                        { y: 100 },
                        { y: 0, duration: 0.3, ease: 'power2.inOut' })
                    .to('.intro-logo .letter-d, .intro-logo .letter-e, .intro-logo .letter-v',
                        { y: -100, duration: 0.2, delay: 1, ease: 'power2.inOut' });
            }, 1.9)
            .add(() => {
                // Harmadik rész
                gsap.timeline()
                    .to('.intro-logo .arrow',
                        { y: 100, x: 80 })
                    .fromTo('.intro-logo .letter, .intro-logo .arrow',
                        { y: 100 },
                        { y: 0, duration: 0.3, ease: 'power2.inOut' })
                    .to('.intro-logo .arrow',
                        { y: 0, x: 0, duration: 0.3, delay: 0.5, ease: 'power2.inOut' });
            }, 3.3)
            .add(() => {
                // Negyedik rész
                gsap.timeline()
                    .to('.intro-logo', {
                        width: logo_params.width + 'px',
                        top: logo_params.top + 'px',
                        left: logo_params.left + 'px',
                        duration: 0.5,
                        ease: 'power2.inOut',
                        onComplete: () => {
                            document.querySelector('.intro-logo').classList.remove('animations-start');
                        }
                    });
            }, 4.8)
            .add(() => {
                document.querySelector('#hemisphere').classList.remove('d-none');
                gsap.fromTo('#hemisphere',
                    { opacity: 0 },
                    { opacity: 1, duration: 1, ease: 'power2.inOut' }
                );
                document.querySelector('.shape-ring').classList.remove('d-none');
                gsap.fromTo('.shape-ring',
                    { opacity: 0 },
                    { opacity: 1, duration: 1, ease: 'power2.inOut' }
                );
                
                document.querySelectorAll('header nav li').forEach((li, index) => {
                    li.style.opacity = 0;
                    li.classList.remove('d-none');
                    gsap.fromTo(li, 
                        { x: 1000, opacity: 0 }, 
                        { x: 0, opacity: 1, duration: 1, delay: (index * 0.5), ease: 'back.out(0.7)', onComplete: () => {
                            document.querySelectorAll('section:not(.hero), footer').forEach((section) => {
                                section.classList.remove('d-none');
                            });
                            
                            gsap.to('.btn-skip', {
                                opacity: 0,
                                duration: 0.2,
                                ease: 'back.out(1.7)'
                            });

                            utils.setCookie('visited', 'true', 365);
                        }}
                    );
                });

                document.querySelector('header .hamburger-menu').classList.remove('d-none');
            }, 5.3)
            .add(() => {
                document.querySelector('.nav-bar').classList.remove('d-none');
                document.querySelector('.scroll-down').classList.remove('d-none');
                document.querySelector('.scroll-up').classList.add('d-none');
                document.querySelectorAll('.nav-bar nav li').forEach((li, index) => {
                    li.style.opacity = 0;
                    li.classList.remove('d-none');
                    gsap.timeline()
                        .from(li, 
                        { scale: 0, opacity: 0 })
                        .to(li,
                            { scale: 1.5, opacity: 1, duration: 0.3, delay: (document.querySelectorAll('.nav-bar nav li').length - index) * 0.2, ease: 'power2.inOut' })
                        .to(li,
                            { scale: 1, duration: .2, ease: 'power2.inOut' });
                });
                document.querySelector('.scroll-down').style.bottom = window.innerHeight + 200 + 'px';
                gsap.fromTo('.scroll-down',
                    { bottom: window.innerHeight + 200 },
                    { bottom: 60, duration: 1, ease: 'power2.out' }
                );
                document.querySelectorAll('.hero .social a').forEach((a, index) => {
                    a.style.opacity = 0;
                    a.classList.remove('d-none');
                    gsap.fromTo(a, 
                        { y: 500, opacity: 0 }, 
                        { y: 0, opacity: 1, duration: 2, delay: index * 0.2, ease: 'back.out(1.7)' }
                    );
                });

                if (document.querySelector('.hero .content .slider')) {
                    document.querySelector('.hero .content .slider').classList.remove('d-none');
                    gsap.timeline()
                    .fromTo('.hero .content .slider',
                    { opacity: 0 },
                    { opacity: 1, duration: 0.5, ease: 'power2.inOut' });
                }
                
                document.querySelector('.services-background-image').classList.remove('d-none');
                
            }, 6.2);
    }, 10);

    const skip = function() {
        utils.setCookie('visited', 'true', 365);
        window.location.reload();
    }

    document.querySelector('.btn-skip').addEventListener('click', skip);
}